// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getProductCates = createAsyncThunk('appProductCates/getProductCates', async (params) => {
  const response = await axios.get('/api/v1/product-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getProductCate = createAsyncThunk('appProductCates/getProductCate', async (id) => {
  const response = await axios.get(`/api/v1/product-cate/${id}`);
  return response;
});

export const addProductCate = createAsyncThunk('appProductCates/addProductCate', async (role, { dispatch, getState }) => {
  const newProductCate = await axios.post('/api/v1/product-cate', role);
  return newProductCate;
});

export const updateProductCate = createAsyncThunk('appProductCates/updateProductCate', async (role, { dispatch, getState }) => {
  const newProductCate = await axios.put(`/api/v1/product-cate/${role.product_cate_id}`, role);
  return newProductCate;
});

export const deleteProductCate = createAsyncThunk('appProductCates/deleteProductCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/product-cate/${id}`);
  return res;
});

export const appProductCatesSlice = createSlice({
  name: 'appProductCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getProductCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getProductCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getProductCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getProductCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getProductCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getProductCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addProductCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addProductCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addProductCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateProductCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateProductCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateProductCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteProductCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteProductCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.product_cate_id != action?.payload?.data?.data?.product_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteProductCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appProductCatesSlice.reducer;
