// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getTemplateCates = createAsyncThunk('appTemplateCates/getTemplateCates', async (params) => {
  const response = await axios.get('/api/v1/template-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getTemplateCate = createAsyncThunk('appTemplateCates/getTemplateCate', async (id) => {
  const response = await axios.get(`/api/v1/template-cate/${id}`);
  return response;
});

export const addTemplateCate = createAsyncThunk('appTemplateCates/addTemplateCate', async (role, { dispatch, getState }) => {
  const newTemplateCate = await axios.post('/api/v1/template-cate', role);
  return newTemplateCate;
});

export const updateTemplateCate = createAsyncThunk('appTemplateCates/updateTemplateCate', async (role, { dispatch, getState }) => {
  const newTemplateCate = await axios.put(`/api/v1/template-cate/${role.template_cate_id}`, role);
  return newTemplateCate;
});

export const deleteTemplateCate = createAsyncThunk('appTemplateCates/deleteTemplateCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/template-cate/${id}`);
  return res;
});

export const appTemplateCatesSlice = createSlice({
  name: 'appTemplateCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getTemplateCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getTemplateCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getTemplateCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getTemplateCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getTemplateCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getTemplateCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addTemplateCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addTemplateCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addTemplateCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateTemplateCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateTemplateCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateTemplateCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteTemplateCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteTemplateCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.template_cate_id != action?.payload?.data?.data?.template_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteTemplateCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appTemplateCatesSlice.reducer;
