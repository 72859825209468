// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getPosts = createAsyncThunk('appPosts/getPosts', async (params) => {
  const response = await axios.get('/api/v1/posts', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getPost = createAsyncThunk('appPosts/getPost', async (id) => {
  const response = await axios.get(`/api/v1/post/${id}`);
  return response;
});

export const getPostStatistics = createAsyncThunk('appPosts/getPostStatistics', async () => {
  const response = await axios.get(`/api/v1/post-statistics`);
  return response?.data?.data;
});

export const addPost = createAsyncThunk('appPosts/addPost', async (post, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/post', post);
  return res;
});

export const deletePost = createAsyncThunk('appPosts/deletePost', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/post/${id}`);
  return res;
});

export const updatePost = createAsyncThunk('appPosts/updatePost', async (postData, { dispatch }) => {
  const res = await axios.put(`/api/v1/post/${postData.post_id}`, postData);
  return res;
});

export const appPostsSlice = createSlice({
  name: 'appPosts',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getPosts.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getPost.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getPostStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getPostStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getPostStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addPost.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addPost.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updatePost.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deletePost.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.post_id != action?.payload?.data?.data?.post_id);
        state.total = state.total - 1;
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appPostsSlice.reducer;
