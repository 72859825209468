// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getDocuments = createAsyncThunk('appDocuments/getDocuments', async (params) => {
  const response = await axios.get('/api/v1/documents', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getDocument = createAsyncThunk('appDocuments/getDocument', async (id) => {
  const response = await axios.get(`/api/v1/document/${id}`);
  return response;
});

export const getDocumentStatistics = createAsyncThunk('appDocuments/getDocumentStatistics', async () => {
  const response = await axios.get(`/api/v1/document-statistics`);
  return response?.data?.data;
});

export const addDocument = createAsyncThunk('appDocuments/addDocument', async (document, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/document', document);
  return res;
});

export const deleteDocument = createAsyncThunk('appDocuments/deleteDocument', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/document/${id}`);
  return res;
});

export const updateDocument = createAsyncThunk('appDocuments/updateDocument', async (documentData, { dispatch }) => {
  const res = await axios.put(`/api/v1/document/${documentData.document_id}`, documentData);
  return res;
});

export const appDocumentsSlice = createSlice({
  name: 'appDocuments',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getDocuments.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getDocument.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getDocumentStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getDocumentStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getDocumentStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addDocument.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateDocument.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteDocument.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.document_id != action?.payload?.data?.data?.document_id);
        state.total = state.total - 1;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appDocumentsSlice.reducer;
