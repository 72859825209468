// ** Navigation imports
import apps from './apps';
import pages from './pages';
import forms from './forms';
import tables from './tables';
import others from './others';
import charts from './charts';
import dashboards from './dashboards';
import uiElements from './ui-elements';

// ** Merge & Export
const devMenus = [...apps, ...pages, ...uiElements, ...forms, ...tables, ...charts, ...others];

const prodMenus = [...apps];
const menus = import.meta.env.PROD ? prodMenus : devMenus;
export default menus;
