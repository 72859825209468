// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import options from './options';
import auth from './authentication';
import files from '@src/views/apps/file/store';
import todo from '@src/views/apps/todo/store';
import chat from '@src/views/apps/chat/store';
import users from '@src/views/apps/user/store/users';
import roles from '@src/views/apps/user/store/roles';
import permissionCates from '@src/views/apps/user/store/permissionCates';
import permissions from '@src/views/apps/user/store/permissions';
import email from '@src/views/apps/email/store';
import kanban from '@src/views/apps/kanban/store';
import invoice from '@src/views/apps/invoice/store';
import calendar from '@src/views/apps/calendar/store';
import ecommerce from '@src/views/apps/ecommerce/store';
import dataTables from '@src/views/tables/data-tables/store';
import posts from '@src/views/apps/post/store/posts';
import postCates from '@src/views/apps/post/store/cates';
import postTags from '@src/views/apps/post/store/tags';
import projects from '@src/views/apps/project/store/projects';
import projectCates from '@src/views/apps/project/store/cates';
import templates from '@src/views/apps/template/store/templates';
import templateCates from '@src/views/apps/template/store/cates';
import sliders from '../views/apps/slider/store/sliders';
import sliderCates from '@src/views/apps/slider/store/cates';
import websites from '@src/views/apps/website/store/websites';
import websiteCates from '@src/views/apps/website/store/cates';
import websiteTags from '@src/views/apps/website/store/tags';
import products from '@src/views/apps/product/store/products';
import productCates from '@src/views/apps/product/store/cates';
import productTags from '@src/views/apps/product/store/tags';
import documents from '@src/views/apps/document/store/documents';
import documentCates from '@src/views/apps/document/store/cates';
import documentTags from '@src/views/apps/document/store/tags';
import addresses from './addresses';
import cloudSevers from '@src/views/apps/cloud-sever/store/cloudSevers';
import hostings from '@src/views/apps/cloud-sever/store/hostings';

import W5D from '@src/views/W5Ds/store/w5d';

const rootReducer = {
  auth,
  options,
  todo,
  files,
  chat,
  email,
  users,
  roles,
  permissionCates,
  permissions,
  posts,
  postCates,
  postTags,
  projects,
  projectCates,
  templates,
  templateCates,
  sliders,
  sliderCates,
  websites,
  websiteCates,
  websiteTags,
  products,
  productCates,
  productTags,
  documents,
  documentCates,
  documentTags,
  hostings,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  addresses,
  cloudSevers,
  W5D,
};

export default rootReducer;
