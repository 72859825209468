import { lazy } from 'react';

const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'));
const DashboardEcommerce = lazy(() => import('../../views/dashboard/ecommerce'));

const DashboardRoutes = [
  {
    path: '/dashboard/analytics',
    element: <DashboardAnalytics />,
    meta: {
      publicRoute: false,
    },
  },
  {
    path: '/dashboard/ecommerce',
    element: <DashboardEcommerce />,
    meta: {
      publicRoute: false,
    },
  },
];

export default DashboardRoutes;
