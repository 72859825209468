// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getRoles = createAsyncThunk('appRoles/getRoles', async (params) => {
  const response = await axios.get('/api/v1/user-cates', params);
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getRole = createAsyncThunk('appRoles/getRole', async (id) => {
  const response = await axios.get(`/api/v1/user-cate/${id}`);
  return response;
});

export const addRole = createAsyncThunk('appRoles/addRole', async (role, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/user-cate', role);
  return res;
});

export const deleteRole = createAsyncThunk('appRoles/deleteRole', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/user-cate/${id}`);
  return res;
});

export const updateRole = createAsyncThunk('appRoles/updateRole', async (data, { dispatch }) => {
  const res = await axios.put(`/api/v1/user-cate/${data.user_cate_id}`, data);
  return res;
});

export const appRolesSlice = createSlice({
  name: 'appRoles',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: true,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get all
      .addCase(getRoles.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getRole.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getRole.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addRole.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
        state.data = [...state.data, data];
        state.total = state.total + 1;
      })
      .addCase(addRole.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateRole.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
        state.data = state.data.map((item) => {
          if (item?.user_cate_id === data?.user_cate_id) {
            return data;
          }
          return item;
        });
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteRole.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.user_cate_id != action?.payload?.data?.data?.user_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appRolesSlice.reducer;
