// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getCloudSevers = createAsyncThunk('appCloudSevers/getCloudSevers', async (params) => {
  const response = await axios.get('/api/v1/cloud-servers', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getCloudSever = createAsyncThunk('appCloudSevers/getCloudSever', async (id) => {
  const response = await axios.get(`/api/v1/cloud-server/${id}`);
  return response;
});

export const addCloudSever = createAsyncThunk('appCloudSevers/addCloudSever', async (cloudData, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/cloud-server', cloudData);
  return res;
});

export const deleteCloudSever = createAsyncThunk('appCloudSevers/deleteCloudSever', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/cloud-server/${id}`);
  return res;
});

export const updateCloudSever = createAsyncThunk('appCloudSevers/updateCloudSever', async (cloudData, { dispatch }) => {
  const res = await axios.put(`/api/v1/cloud-server/${cloudData.cloud_server_id}`, cloudData);
  return res;
});

export const appCloudSeversSlice = createSlice({
  name: 'appCloudSevers',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getCloudSevers.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getCloudSevers.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getCloudSevers.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getCloudSever.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getCloudSever.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getCloudSever.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addCloudSever.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addCloudSever.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.data.unshift(data);
        state.total = state.total + 1;
        state.selectedLoading = false;
      })
      .addCase(addCloudSever.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateCloudSever.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateCloudSever.fulfilled, (state, action) => {
        const updatedData = action?.payload?.data?.data;
        const index = state.data.findIndex((item) => item?.cloud_server_id === updatedData?.cloud_server_id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.selected = updatedData;
        state.selectedLoading = false;
      })
      .addCase(updateCloudSever.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteCloudSever.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteCloudSever.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.cloud_server_id != action?.payload?.data?.data?.cloud_server_id);
        state.total = state.total - 1;
      })
      .addCase(deleteCloudSever.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appCloudSeversSlice.reducer;
