// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getProductTags = createAsyncThunk('appProductTags/getProductTags', async (params) => {
  const response = await axios.get('/api/v1/product-tags', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getProductTag = createAsyncThunk('appProductTags/getProductTag', async (id) => {
  const response = await axios.get(`/api/v1/product-tag/${id}`);
  return response;
});

export const addProductTag = createAsyncThunk('appProductTags/addProductTag', async (data, { dispatch, getState }) => {
  const newProductTag = await axios.post('/api/v1/product-tag', data);
  return newProductTag;
});

export const updateProductTag = createAsyncThunk('appProductTags/updateProductTag', async (data, { dispatch, getState }) => {
  const newProductTag = await axios.put(`/api/v1/product-tag/${data.product_tag_id}`, data);
  return newProductTag;
});

export const deleteProductTag = createAsyncThunk('appProductTags/deleteProductTag', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/product-tag/${id}`);
  return res;
});

export const appProductTagsSlice = createSlice({
  name: 'appProductTags',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getProductTags.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getProductTags.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getProductTags.rejected, (state, action) => {
        state.loading = false;
      })

      // ** get item
      .addCase(getProductTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getProductTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getProductTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addProductTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addProductTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addProductTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateProductTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateProductTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateProductTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteProductTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteProductTag.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.product_tag_id != action?.payload?.data?.data?.product_tag_id);
        state.total = state.total - 1;
      })
      .addCase(deleteProductTag.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appProductTagsSlice.reducer;
