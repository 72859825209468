import mock from '@src/@fake-db/mock.js';

const data = {
  pricing: {
    startupPlan: {
      title: 'Startup',
      lablePrice: 'Trọn gói',
      subtitle: 'A simple start for everyone',
      monthlyPrice: '2,690,000 đ',
      yearlyPlan: {
        perMonth: 0,
        totalAnnual: 0,
      },
      planBenefits: [
        'Thời gian hoàn thành 1 - 3 ngày',
        'Giao diện theo mẫu có sẵn',
        'Tặng tên miền .com | .net',
        'Tặng dung lượng lưu trữ 800 MB',
        'Tặng SSL bảo mật website',
        'Băng thông không giới hạn',
        'Hỗ trợ nhập liệu 10 sản phẩm | 10 bài viết',
        'Chuẩn SEO 100%',
        'Tính năng web cơ bản',
        'Bàn giao đầy đủ Source code',
        'Cung cấp tài liệu hướng dẫn',
        'Bảo hành suốt thời gian sử dụng',
        'Hỗ trợ kỹ thuật 24/7/365 Free',
      ],
      planRenewal: ['Gia hạn 1 năm: 800,000 đ', 'Gia hạn 2 năm: 1,440,000', 'Gia hạn 3 năm: 1,920,000', 'Gia hạn 5 năm: 2,800,000'],
      popular: false,
    },
    businessPlan: {
      title: 'Business',
      lablePrice: 'Trọn gói',
      subtitle: 'For small to medium businesses',
      monthlyPrice: '3,990,000 đ',
      yearlyPlan: {
        perMonth: 40,
        totalAnnual: 480,
      },
      planBenefits: [
        'Thời gian hoàn thành 3 - 5 ngày',
        'Giao diện theo mẫu & edit',
        'Tặng tên miền .com | .net',
        'Tặng dung lượng lưu trữ 1 GB',
        'Tặng SSL bảo mật website',
        'Băng thông không giới hạn',
        'Hỗ trợ nhập liệu 20 sản phẩm | 20 bài viết',
        'Chuẩn SEO 100%',
        'Tính năng web CN',
        'Bàn giao đầy đủ Source code',
        'Cung cấp tài liệu hướng dẫn',
        'Bảo hành suốt thời gian sử dụng',
        'Hỗ trợ kỹ thuật 24/7/365 Free',
      ],
      planRenewal: ['Gia hạn 1 năm: 1,000,000', 'Gia hạn 2 năm: 1,800,000', 'Gia hạn 3 năm: 2,400,000', 'Gia hạn 5 năm: 3,500,000'],
      popular: true,
    },
    professionalPlan: {
      title: 'Professional',
      lablePrice: 'Trọn gói',
      subtitle: 'Solution for big organizations',
      monthlyPrice: '5,890,0000 đ',
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960,
      },
      planBenefits: [
        'Thời gian hoàn thành 5 - 10 ngày',
        'Giao diện thiết kế theo ý',
        'Tặng tên miền .com | .net',
        'Tặng dung lượng lưu trữ 1,5 GB',
        'Tặng SSL bảo mật website',
        'Băng thông không giới hạn',
        'Hỗ trợ nhập liệu 30 sản phẩm | 30 bài viết',
        'Chuẩn SEO 100%',
        'Tính năng web cơ bản',
        'Bàn giao đầy đủ Source code',
        'Cung cấp tài liệu hướng dẫn',
        'Bảo hành suốt thời gian sử dụng',
        'Hỗ trợ kỹ thuật 24/7/365 Free',
      ],
      planRenewal: ['Gia hạn 1 năm: 1,500,000', 'Gia hạn 2 năm: 2,700,000', 'Gia hạn 3 năm: 3,600,000', 'Gia hạn 5 năm: 5,250,000'],
      popular: false,
    },
    advancedPlan: {
      title: 'Advanced',
      lablePrice: 'Theo yêu cầu',
      subtitle: 'Solution for big organizations',
      monthlyPrice: '7,990,000 đ',
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960,
      },
      planBenefits: [
        'Thời gian hoàn thành > 10 ngày',
        'Giao diện thiết kế độc quyền',
        'Tặng tên miền .com | .net',
        'Tặng dung lượng lưu trữ 2 GB',
        'Tặng SSL bảo mật website',
        'Băng thông không giới hạn',
        'Hỗ trợ nhập liệu 40 sản phẩm | 40 bài viết',
        'Chuẩn SEO 100%',
        'Tính năng web theo ý',
        'Bàn giao đầy đủ Source code',
        'Cung cấp tài liệu hướng dẫn',
        'Bảo hành suốt thời gian sử dụng',
        'Hỗ trợ kỹ thuật 24/7/365 Free',
      ],
      planRenewal: ['Gia hạn 1 năm: 2,000,000', 'Gia hạn 2 năm: 3,600,000', 'Gia hạn 3 năm: 4,800,000', 'Gia hạn 5 năm: 7,000,000'],
      popular: false,
    },
    qandA: [
      {
        question: 'Tôi được dùng thử dịch vụ trong vòng bao lâu? Nếu tôi muốn mua gói dịch vụ chính thức thì tôi phải làm gì?',
        ans: 'Bạn có thể đăng ký để được trải nghiệm miễn phí Website trong vòng 15 ngày. Chuyên viên tư vấn của Kinhdoanhweb sẽ luôn đồng hành với bạn trong suốt thời gian dùng thử để giải đáp các yêu cầu, thắc mắc của bạn cũng như hỗ trợ bạn khi mua gói dịch vụ chính thức.',
      },
      {
        question: ' Trong quá trình sử dụng web có phát sinh thêm phí gì nữa không?',
        ans: 'Chào bạn, bạn chỉ cần trả phí theo gói web bạn chọn là có một website hoàn chỉnh với đầy đủ các tính năng mà chúng tôi đã liệt kê bên trên. Nếu bạn có yêu cầu làm thêm mà gói web bạn chọn ban đầu không bao gồm thì mới phát sinh chi phí. Nếu bạn chưa rõ, vui lòng liên hệ với đội ngũ chăm sóc Khách hàng của Kinhdoanhweb để được tư vấn kỹ hơn.',
      },
      {
        question: 'Tôi muốn tự update nội dung và thay đổi giao diện Web có được không?',
        ans: 'Có, khi website hoàn thiện, Kinhdoanhweb sẽ bàn giao và hướng dẫn bạn sử dụng, sau đó bạn có thể tự cập nhật nội dung, chỉnh sửa website theo theo ý mình. Trường hợp bạn muốn thay đổi các mục liên quan đến mã code lập trình, vui lòng liên hệ bộ phận kỹ thuật của Kinhdoanhweb để được hỗ trợ.',
      },
      {
        question: 'Trong quá trình sử dụng, tôi có thể nâng cấp gói dịch vụ không?',
        ans: 'Trong quá trình sử dụng dịch vụ, bạn hoàn toàn có thể nâng cấp gói dịch vụ của mình lên gói cao hơn. Chi phí nâng cấp được tính bằng "số tiền chênh lệch giữa 2 gói x số tháng còn lại".',
      },
      {
        question: 'Sử dụng và Quản trị website có dễ không khi tôi không hề có chuyên môn cũng như đội ngũ vận hành?',
        ans: 'Kinh doanh web mang đến cho bạn những tính năng phong phú nhưng vô cùng dễ sử dụng để giúp bạn dễ dàng quản trị, vận hành website theo ý muốn mà không cần phải có kiến thức chuyên môn.Ngoài ra chúng tôi còn cung cấp tài liệu, videos hướng dẫn chi tiết..',
      },
      {
        question: 'Nếu website hết dung lượng, tôi muốn mua thêm dung lượng thì có được không và chi phí như thế nào?',
        ans: 'Bạn hoàn toàn có thể mua thêm dung lượng cho Website với mức phí là 1.000đ/1MB/năm . Nếu mua dung lượng từ 2 năm trở lên sẽ được chiết khấu từ 10% - 18%.',
      },
    ],
  },
};

mock.onGet('/pricing/data').reply(() => [200, data.pricing]);
