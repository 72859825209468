// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getClientGlobals = createAsyncThunk('W5D/getClientGlobals', async (params, { dispatch, getState }) => {
  const currentData = getState().W5D.common.data?.options;

  if (currentData) {
    return {
      data: getState().W5D.common.data,
    };
  }
  const response = await axios.get('/api/v1/config/client-globals');
  return {
    params,
    data: response.data.data,
  };
});

export const W5DSlice = createSlice({
  name: 'W5D',
  initialState: {
    common: {
      loading: false,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get client globals
      .addCase(getClientGlobals.pending, (state, action) => {
        state.common.loading = true;
      })
      .addCase(getClientGlobals.fulfilled, (state, action) => {
        state.common.data = action.payload.data;
        state.common.loading = false;
      })
      .addCase(getClientGlobals.rejected, (state, action) => {
        state.common.loading = false;
      });
  },
});
export default W5DSlice.reducer;
